.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
